
































import { Component, Vue } from 'vue-property-decorator'
import { locales } from '@/i18n'
import { LocalStorageManager } from '@/services/Managers'

@Component
export default class Locale extends Vue {
  locales = locales

  get availableLocales() {
    return this.locales.filter(e => e.locale != this.$i18n.locale)
  }

  get currentLocale() {
    return this.locales.find(l => l.locale === this.$i18n.locale)
  }

  switchLocale({ locale }) {
    this.$i18n.locale = locale
    LocalStorageManager.setItem('OGG_Locale', locale)
  }
}
